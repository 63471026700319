.mr-top{

    margin-top: 40px;
    margin-bottom:40px;
}

.mr-left{
    margin-left: 20px;
}

.example {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  label {
    display: flex;
    flex-direction: row;
    /* justify-content: flex-end; */
    text-align: right;
    width: 150px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  
  input {
    
    /* margin-left: 10px; */
    /* margin-bottom: 20px !important; */
    width: 250px !important;
  }