.importCsvCard {
  height: 100%;
}

.addProdBrandBtnContainer {
  display: flex;
  justify-content: center;
  width: '100%';
  margin-top: 24px !important;
}
