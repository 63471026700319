.card {
  width: 100%;
  max-width: 30rem;
  margin-top: 30px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.ant-input-affix-wrapper{
  border-color: none !important;
}

.container-fluid div .flex{
  display: none !important;
}
.submitBtnContainer {
  display: flex;
  justify-content: center;
}

.loginTableContainer {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
}


