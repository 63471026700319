.mr-top {
  margin-top: 40px;
  margin-bottom: 40px;
}

.center {
  display: flex !important;
  justify-content: center !important;
}

.mr-left {
  margin-left: 20px;
}

.example {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
