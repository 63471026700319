.wrapper {
	display: grid;
	grid-template-columns: 2fr 3fr 2fr;
	font-size: 12px;
	padding: 10px 0;
	width: 1000px;
	max-width: 90%;
	margin: 0 auto;
	grid-auto-rows: minmax(70px, auto);
}

.wrapper > div {
	padding: 0.6em;
}

.box1 {
	grid-column: 1;
	grid-row: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.box2 {
	grid-column: 2;
	grid-row: 1;
	text-align: center;
}

.box3 {
	grid-column: 3;
	grid-row: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.box4 {
	grid-column: 1/4;
	grid-row: 2;
	text-align: center;
}

.box5 {
	grid-column: 1/2;
	grid-row: 3;
}

.box6 {
	grid-column: 3/4;
	grid-row: 3;
}

.box7 {
	justify-content: stretch;
	grid-column: 1/4;
	grid-row: 4;
}

.box8 {
	grid-column: 1/2;
	grid-row: 6;
}

.box9 {
	grid-column: 3/4;
	grid-row: 5;
}

.box10 {
	grid-column: 3/4;
	grid-row: 10;
}

.box11 {
	grid-column: 1/2;
	grid-row: 10;
}

.box12 {
	grid-column: 1/4;
	grid-row: 11;
	text-align: center;
}

.box13 {
	grid-column: 1/3;
	grid-row: 5;
}

.box14 {
	grid-column: 1/4;
	grid-row: 7;
}

.footer {
	position: fixed;
	left: 180;
	bottom: 0;
	text-align: center;
	width: 1000px;
	max-width: 90%;
}

body {
	font-family: "Inter", sans-serif;
}
table {
	width: 100%;
	border-collapse: collapse;
}
table.table1,
table.table1 th,
table.table1 td {
	border: 1px solid silver;
}
table.table1 th,
table.table1 td {
	text-align: left;
	padding: 5px;
}
table.table1 tr:nth-child(even) {
	background-color: #f2f2f2;
}

table.table2 {
	border-collapse: collapse;
	width: 100%;
}

table.table2 th,
table.table2 td {
	padding: 8px;
	text-align: left;
	border-bottom: 1px solid #ddd;
}
hr.hr1 {
	border-top: 1px dotted rgb(63, 63, 63);
}

.button-size {
	padding: 8px;
	margin-top: -4px !important;
}
